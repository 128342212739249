import { navigate } from "gatsby"
import React, { Component } from "react"
import Cookies from "universal-cookie"

export function createOverlay(contentMap) {
  return contentMap.overlay.value
}

export function createContentList(contentMap, commonContentMap) {
  return [
    {
      markdown: contentMap.markdown.value,
    },
    {
      // header: contentMap.shipping_costs_header.value,
      header: (
        <div id="cookie-consent">{contentMap.cookie_manage_consent.value}</div>
      ),
      component: <CookieSettings contentMap={contentMap} />,
    },
  ]
}

class CookieSettings extends Component {
  state = {
    sbfAnalytical: false,
    sbfTracking: false,
    btnDisabled: true,
    goToPrevious: false,
  }
  componentDidMount() {
    const cookies = new Cookies()

    let sbfAnalyticalCookie = cookies.get("sbf-gdpr-analytical")
    let sbfTrackingCookie = cookies.get("sbf-gdpr")
    let btnDisabled = true
    let goToPrevious = false

    // Banner is still showing in these cases
    if (!sbfAnalyticalCookie || !sbfTrackingCookie) {
      btnDisabled = false
      goToPrevious = true
      if (!sbfAnalyticalCookie) {
        sbfAnalyticalCookie = "true"
      }
      if (!sbfTrackingCookie) {
        sbfTrackingCookie = "true"
      }
    }

    this.setState({
      sbfAnalytical: sbfAnalyticalCookie === "true",
      sbfTracking: sbfTrackingCookie === "true",
      btnDisabled: btnDisabled,
      goToPrevious: goToPrevious,
    })
  }

  setLoading = () => {
    this.loading = true
  }

  doneLoading = () => {
    this.loading = false
  }

  setCookie(name, input) {
    const cookies = new Cookies()

    cookies.set(name, input ? "true" : "false", {
      path: "/",
    })
  }

  onClick = event => {
    let { sbfAnalytical, sbfTracking, btnDisabled, goToPrevious } = this.state

    const id = event.target.id

    if (this.loading) {
      return
    }
    this.setLoading()

    switch (id) {
      case "sbf-gdpr-analytical":
        sbfAnalytical = !sbfAnalytical
        this.setState(
          {
            sbfAnalytical: sbfAnalytical,
            btnDisabled: false,
          },
          this.doneLoading
        )
        break
      case "sbf-gdpr":
        sbfTracking = !sbfTracking
        this.setState(
          {
            sbfTracking: sbfTracking,
            btnDisabled: false,
          },
          this.doneLoading
        )
        break
      case "accept":
        if (btnDisabled) {
          return
        }

        this.setCookie("sbf-gdpr-analytical", sbfAnalytical)
        this.setCookie("sbf-gdpr", sbfTracking)

        if (goToPrevious) {
          navigate(-1)
        } else {
          this.setState(
            {
              btnDisabled: true,
            },
            this.doneLoading
          )
        }
        break
    }
  }

  getDisabledCss() {
    const { btnDisabled } = this.state

    let out = "btn btn-dark col-12 col-lg-2"

    if (btnDisabled) {
      out += " disabled"
    }

    return out
  }

  render() {
    const { contentMap } = this.props
    const { sbfAnalytical, sbfTracking, btnDisabled } = this.state

    return (
      <div>
        <div className="row cart-item">
          <div className="col-11">{contentMap.cookie_functional.value}</div>
          <div className="col-1">
            <input
              id="sbf-gdpr-functional"
              name="sbf-gdpr-functional"
              type="checkbox"
              className="terms-checkbox-input terms-checkbox-input-border"
              checked={true}
              disabled={true}
            />
          </div>
        </div>
        <div className="row cart-item">
          <div className="col-11">{contentMap.cookie_analytical.value}</div>
          <div className="col-1">
            <input
              id="sbf-gdpr-analytical"
              name="sbf-gdpr-analytical"
              type="checkbox"
              className="terms-checkbox-input terms-checkbox-input-border"
              checked={sbfAnalytical}
              onChange={this.onClick}
            />
          </div>
        </div>
        <div className="row cart-item">
          <div className="col-11">{contentMap.cookie_tracking.value}</div>
          <div className="col-1">
            <input
              id="sbf-gdpr"
              name="sbf-gdpr"
              type="checkbox"
              className="terms-checkbox-input terms-checkbox-input-border"
              checked={sbfTracking}
              onChange={this.onClick}
            />
          </div>
        </div>
        <div className="row cart-item justify-content-end">
          <div
            id="accept"
            name="accept"
            className={this.getDisabledCss()}
            onClick={this.onClick}
            disabled={btnDisabled}
          >
            {contentMap.cookie_save.value}
          </div>
        </div>
      </div>
    )
  }
}
